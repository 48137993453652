<template>
  <div>
    <van-cell-group title="申请单">
      <van-field readonly label="分类" v-model="stock.CAT_NAME" />
      <van-field readonly label="物品" v-model="stock.NAME" />
      <van-field readonly v-model="model.BODY.CNT" type="digit" label="数量" />
      <van-field readonly label="所属班级" v-model="model.BODY.CLASS_NAME" />
      <van-field readonly label="提交人" v-model="model.STEA_NAME" />
      <van-field v-model="model.BODY.DESCRIPTION"  label="备注" />
      <van-field v-model="model.BODY.DESC" type="textarea" label="处理意见" :rows="2" />
    </van-cell-group>
    <div style="margin: 15px">
      <van-button type="primary" size="large" icon="success" block @click="submit">确认领取</van-button>
      <br />
      <van-button type="warning" size="large" icon="revoke" block @click="refuse">拒绝领取</van-button>
      <br />
      <van-button plain type="default" size="large" icon="cross" block to="/wfl/pro">取消返回</van-button>
      <van-overlay :show="ret.loading">
        <van-loading size="30">正在提交。。。</van-loading>
      </van-overlay>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  name: "new",
  data() {
    return {
      model: {
        CONF_SN:"mat_apply",
        BODY:{}
      },
      cat:{
        show:false,
        ls:[],
        txt:""
      },
      stock:{},
      ed_show:false,
      cla:{
        show:false,
        ls:[],
      },
      ret:{
        loading:false
      }
    }
  },
  created() {
    if (this.$route.params.BODY==undefined) this.$router.go(-1);
    else{
      this.model=this.$route.params;
      this.model.BODY=JSON.parse(this.model.BODY);
      this.getStock(this.model.BODY.ST_ID)
    }
  },
  methods:{
    getStock(id){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/Mobile/MAT/MStockApi/Get",
        data: {ID:id},
        completed:function (m){
          self.stock=m.DATA
        }
      })
    },
    submit(){
      let self=this;
      self.ret.loading=true;
      this.whale.remote.getResult({
        url:"/api/Mobile/WFL/ActApi/Next",
        data:this.model,
        finally(){
          self.ret.loading=false;
        },
        completed(){
          self.$dialog.alert({
            title:"完成",
            message:"物料已成功领取！",
            theme:"round",
          }).then(()=>{self.$router.go(-1);})
        }
      })
    },
    refuse(){
      let self=this;
      self.ret.loading=true;
      this.whale.remote.getResult({
        url:"/api/Mobile/WFL/ActApi/Refuse",
        data: {ID:this.model.ID,DESC:this.model.DESC},
        finally(){self.ret.loading=false;},
        completed:function (){
          self.$dialog.alert({
            title:"已拒绝",
            theme:"round",
          }).then(()=>{self.$router.go(-1);})
        }
      })
    }
  }
}
</script>

